<template>
<b-card no-body>
<div class="row">
  <div class="container col-sm-10">
    <button v-for="(sessions, studentIndex) in groupedSessions"  @click="scrollIntoView" v-bind:key="sessions[0].studentId"  type="button" class="btn btn-outline-dark mt-5 mr-2 float-left btn-lg" v-bind:href="'#student_' + studentIndex">
    <emoticon-excited-outline-icon fillColor="#32c2d6"/>
      {{sessions[0].studentName}}
    </button>
  </div>

    
</div>
<b-card-body id="scrollspy-el" class="container mt-4">
  <div v-for="(sessions, studentIndex) in groupedSessions" v-bind:id="'student_' + studentIndex" v-bind:key="sessions[0].studentId" class="row">
    <div class="container text-left">
      <h2>{{sessions[0].studentName}}</h2>
    </div>
    <div class="mt-3">
      <div class="container text-left">
      <div class="row mb-5 w-100" id="metrics-box">
        <div class="container ml-2 pl-0 pr-0 col-sm-12">
          <div class="row text-center">
              <div class="col-12 col-md-3 mr-md-5 mb-3 mb-xl-2">
                <div class="number-wrapper p-5">
                  <div class="col-sm-12 h6">廣東話</div>
                  <number
                  class="h3 col-sm-9 transition"
                  ref="number2"
                  :from="numberFrom"
                  :format="numberFormat"
                  :to="groupedSessionsStats[studentIndex].languageMinutes.cantonese"/>小時
                </div>
              </div>
              <div class="col-12 col-md-3 mr-md-5 mb-3 mb-xl-2">
                <div class="number-wrapper p-5">
                  <div class="col-sm-12 h6">普通話</div>
                  <number
                  class="bold h3 col-sm-9 transition"
                  ref="number3"
                  :from="numberFrom"
                  :format="numberFormat"
                  :to="groupedSessionsStats[studentIndex].languageMinutes.putonghua"/>小時
                </div>
              </div>
              <div class="col-12 col-md-3 mr-md-5 mb-3 mb-xl-2">
                <div class="number-wrapper p-5"> 
                 <div class="col-sm-12 h6">英文</div>
                  <number
                  class="h3 col-sm-9 transition"
                  ref="number4"
                  :from="numberFrom"
                  :format="numberFormat"
                  :to="groupedSessionsStats[studentIndex].languageMinutes.english"/>小時
                </div>
              </div>
              <div class="container d-md-none d-md-none col-12 ml-0">
                <Pie id="my-chart-id-2" :options="pieOptions" :data="groupedSessionsStats[studentIndex].onlineOfflineSessionData" />
              </div>
          </div>
        </div>
        <div class="col-12 col-md-8 mt-3 mt-md-5 pl-0 pr-0 pl-md-1 pr-md-1">
          <Line id="my-chart-id" :options="lineChartOptions" :data="groupedSessionsStats[studentIndex].participationData" />
        </div>
        <div class="container d-none d-md-block col-sm-4 col-lg-3 mt-5">
          <Pie id="my-chart-id-2" :options="pieOptions" :data="groupedSessionsStats[studentIndex].onlineOfflineSessionData" />
        </div>
        <div v-if="teacherViewing"  class="col-12 col-md-8 mt-3 mt-md-5 pl-0 pr-0 pl-md-1 pr-md-1">
          <Line id="speaker-analysis-line-chart" :options="speakerAnalysisLineChartOptions" :data="groupedSessionsStats[studentIndex].speakerAnalysisData" />
        </div>
        <div v-if="teacherViewing" class="container d-none d-md-block col-sm-4 col-lg-3 mt-5">
          <div class="container">
            <div class="row">
              <div class="col p-3 number-wrapper col-sm-8">
                <img src="/img/students with circle.png" class="w-100">
              </div>
              <p class="col col-sm-4 col-sm-offset-4 h3 metrics-percentages overlay-text">
                {{Math.round(groupedSessionsStats[studentIndex].averageStudentParticipationPercent)}}%
              </p>
            </div>
            <div class="row mt-4">
              <div class="col p-3 number-wrapper col-sm-8">
                <img src="/img/teacher with circle.png" class="w-100">
              </div>
              <div class="col col-sm-4 col-sm-offset-4 h3 metrics-percentages overlay-text">
                {{Math.round(groupedSessionsStats[studentIndex].averageTeacherParticipationPercent)}}%
              </div>
            </div>
          </div>
          <!-- 
          <Pie id="student-teacher-average-pie" :options="studentAveragePieOptions" :data="groupedSessionsStats[studentIndex].studentTeacherAverage"  />
          -->
        </div>

        
      </div>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab v-for="(tabSessions, name, tabIndex) in groupedSessionsByCourse[studentIndex]" v-bind:key="tabIndex" :title="name" active>

              <div v-for="(session, index) in tabSessions" v-bind:key="index" v-show="index < sessionLimit" class="row">
                <div class="col-sm-2">
                    <img  v-bind:src="session.teacherImage" class="img-fluid col-4 col-sm-10 rounded-circle" alt="Teacher Image">
                </div>
                <div class="col-sm-10">
                    <h5>日期: {{session.date}}</h5>
                    <img v-if="session.bookCoverImageUrl" v-bind:src="session.bookCoverImageUrl" class="img-fluid col-4 col-sm-3 pl-1 pb-4" alt="Book Image"/>

                    <h5>課堂導師: {{session.teacherName}}</h5>
                    <div v-if="session.sessionLanguage">教學語言: {{session.sessionLanguage}}</div>
                    <div v-if="session.sessionType">課堂形式: {{session.sessionType}}</div>
                    <div v-if="session.ratings" class="mt-3 mb-3">
                     <p>互動溝通: <forum-icon fillColor="#499de0" v-for="index in Number(session.ratings.participation)" :key="index" />
                     </p>
                     <p>快樂指數: <emoticon-excited-outline-icon fillColor="#32c2d6" v-for="index in Number(session.ratings.happy)" :key="index" />
                     </p>
                     <p>專注投入: <lightbulb-variant-outline-icon fillColor="#f8ee6e" v-for="index in Number(session.ratings.attention)" :key="index" />
                     </p>
                     <p>安坐: <library-outline-icon fillColor="#3bbb24" v-for="index in Number(session.ratings.sitting)" :key="index" />
                     </p>
                    </div>
                    <h6>整體評語:</h6>
                    <button class="btn btn-primary" v-if="!audiosPlaying[session.audioRef]" v-on:click="playAudio(session.audioRef)">
                    <play-icon />
                    </button>
                    <button class="btn btn-primary" v-if="audiosPlaying[session.audioRef]" v-on:click="pauseAudio(session.audioRef)">
                    <pause-icon />
                    </button>
                    <p class="mt-4">{{session.teacherComments}}

                    </p>
                    <h6 v-if="session.speakerAnalysis && teacherViewing">學生積極參與分析:</h6>
                    <p v-if="session.speakerAnalysis && teacherViewing">Only available for internal use:</p>
                    <p v-if="session.speakerAnalysis && session.speakerAnalysis.studentSilenceInfo">
                    
                      <!--ANALYSIS INFO (student-{{tabIndex}}-{{index}})
                      {{session.studentAudioFileLink}}
                      
                      
                      <div v-for="sessionData in session.speakerAnalysis.segmentsData">
                      {{sessionData.startTime/60}} - {{sessionData.endTime/60}}: {{sessionData.transcription[0]?.alternatives?.[0].transcript}}</div>
                      -->

                      
                    </p>
                    <a v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed" :href="session.studentAudioFileLink">
                    Audio:</a>
                     <WaveSurferPlayer v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.studentAudioFileLink" :options="{
                      height: 48,
                      waveColor: 'gray',
                      progressColor: 'red',
                      barGap: 5,
                      barWidth: 5,
                      barRadius: 8,
                      duration: 80,
                      url: session.studentAudioFileLink,
                    }" @waveSurfer="(ws) => readyWaveSurferHandler(ws, 'student-' + tabIndex + '-' + index, 'student', session.speakerAnalysis.studentTalkingLengths)" @timeupdate="(time: number) => timeUpdateHandler(time, 'student-' + tabIndex + '-' + index)" />

                    <p v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.studentAudioFileLink">{{ wavesurferCurrentTimes['student-' + tabIndex + '-' + index] }}</p>
                    <button  v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.studentAudioFileLink"  class="btn btn-primary" @click="playWavesurfer('student-' + tabIndex + '-' + index)">
                      <play-icon v-if="!wavesurfersPlaying['teacher-' + tabIndex + '-' + index] && wavesurfersPlaying['teacher-' + tabIndex + '-' + index] !== false  || wavesurfersPlaying['teacher-' + tabIndex + '-' + index] === false" fillColor="#ffffff" />
                      <pause-icon v-if="wavesurfersPlaying['teacher-' + tabIndex + '-' + index] === true" fillColor="#ffffff" />
                    </button>

                    <WaveSurferPlayer v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.teacherAudioFileLink" :options="{
                      height: 48,
                      waveColor: 'gray',
                      progressColor: 'red',
                      barGap: 5,
                      barWidth: 5,
                      barRadius: 8,
                      duration: 80,
                      url: session.teacherAudioFileLink,
                    }" @waveSurfer="(ws) => readyWaveSurferHandler(ws, 'teacher-' + tabIndex + '-' + index)" @timeupdate="(time: number) => timeUpdateHandler(time, 'teacher-' + tabIndex + '-' + index)" />

                    <p v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.teacherAudioFileLink"> {{ wavesurferCurrentTimes['teacher-' + tabIndex + '-' + index] }}</p>
                    <button  v-if="session.speakerAnalysis && teacherViewing && session.speakerAnalysis.completed && session.teacherAudioFileLink"  class="btn btn-primary" @click="playWavesurfer('teacher-' + tabIndex + '-' + index)">
                      <play-icon v-if="!wavesurfersPlaying['teacher-' + tabIndex + '-' + index] && wavesurfersPlaying['teacher-' + tabIndex + '-' + index] !== false  || wavesurfersPlaying['teacher-' + tabIndex + '-' + index] === false" fillColor="#ffffff" />
                      <pause-icon v-if="wavesurfersPlaying['teacher-' + tabIndex + '-' + index] === true" fillColor="#ffffff" />
                    </button>
                    <div class="row" v-if="session.speakerAnalysis && session.speakerAnalysis.completed == false && teacherViewing">
                      正在運行數據分析，分析完成可以在這裡查看結果
                    </div>
                    <div class="row mb-5 mt-5 w-100" v-if="session.speakerAnalysis && session.speakerAnalysis.completed && teacherViewing">
                      <div class="container ml-2 pl-0 pr-0 col-lg-4 col-sm-12">
                        <Pie :id="'pie-chart-id-speaker-' + tabIndex + '-' + index" :options="pieOptions"  :plugins="plugins" :data="{
                          labels: ['導師', '學生'],
                          datasets: [
                              {
                                  data: [Number(session.speakerAnalysis.teacherPercent), Number(session.speakerAnalysis.studentPercent)],
                                  backgroundColor: ['#41B883', '#42c2d5']
                              }
                          ]
                        }" />
                          <div class="row text-center">
                              <div class="col-12 col-md-3 mr-md-5 mb-3 mb-xl-2"/>
                          </div>
                      </div>


                    </div>
                    <div v-if="teacherViewing && !session.speakerAnalysis">
                    <button @click="clickAudioUploadButtonStudent(session.audioRef)" :disabled="session.studentAudioUploaded" class="btn btn-primary mr-2">
                      <span></span>
                      上載學生檔案
                    </button>
                    <button @click="clickAudioUploadButtonTeacher(session.audioRef)" :disabled="session.teacherAudioUploaded" class="btn btn-primary">
                     上載導師檔案
                    </button>
                    <span v-if="session.audioUploadProgress">
                      正在上載檔案: {{session.audioUploadProgress}}%
                    </span>        
                    <input type="file" v-bind:ref="'student_file_' + session.audioRef" style="display: none" @change="uploadAudioFile(session, 'student')"/>
                    <input type="file" v-bind:ref="'teacher_file_'
                     + session.audioRef" style="display: none" @change="uploadAudioFile(session, 'teacher')"/>
                    </div>

                <div class="container">
                  <div class="justify-content-left">
                      <b-carousel
                        v-if="session.studentPictureLinks"
                        id="carousel-1"
                        v-model="slide"
                        :interval="4000"
                        controls
                        indicators
                        background="#ababab"
                        style="text-shadow: 1px 1px 2px #333;"
                        @sliding-start="onSlideStart"
                        @sliding-end="onSlideEnd"
                      >
                        <!-- Text slides with image -->
                        <b-carousel-slide
                          v-for="pictureLink in session.studentPictureLinks"
                          v-bind:img-src="pictureLink"
                          v-bind:key="pictureLink"
                  
      
                        ></b-carousel-slide>
                      </b-carousel>


                  </div>
                </div>
                </div>
                <div class="col-sm-12">
                  <hr class="mt-12"/>
                </div>
          </div>




          </b-tab>
        </b-tabs>


        </div>
     </div>  
    </div>
    <div class="container">
      <button class="btn btn-primary" v-show="(sessions.length > 1) && (sessionLimit == 3)" v-on:click="sessionLimit = 9999">
        更多
      </button>
      <button class="btn btn-primary" v-show="(sessions.length > 1) && (sessionLimit > 3)" v-on:click="sessionLimit = 3">
        更少
      </button>
    </div>
  </div>
</b-card-body>
</b-card>
<!-- Footer -->
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">Powered by iSpeech AI Speech & Language Technology © 2023
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->

</template>
<style>
.big-lightbulb {
    font-size: 1.4em;
}
.focus-point-badge {
  margin: 0.2em;
  background-color: #c3d4e4;
  color: #212529;
  font-size: 0.9em;
   padding: 0.5em;
   border-radius: 2px;
}
.word-badge {
  background-color: #c3d4e4;
  white-space: normal;
  color: #212529;
  font-size: 1.2em;
  line-height: 1.5rem;
   padding: 0.6em;
   margin-left: 0.5em;
    border-radius: 2px;
}
.register-btn {
    position: absolute; 
    bottom: 10px;
    right: 10px;
}
.btn-primary {
    background-color: #42c2d5;
    border-color: #42c2d5;
}
.carousel {
  height:360px;
}
.carousel-inner > .carousel-item > img {

  height:360px;
  object-fit: contain;

}
.container canvas {
  background: white;
  border-radius: 18px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
  padding: 20px;
}
#metrics-box {
  background: rgb(245 245 245);
  border-radius: 12px;
  padding: 20px;
}
.number-wrapper {
  background: white;
  border-radius: 15px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18);
}
@media (max-width: 1200px) {
  .metrics-percentages {
    font-size: 20px;
  }
}

</style>

<script>
import ForumIcon from 'vue-material-design-icons/Forum.vue';
import PlayIcon from 'vue-material-design-icons/Play.vue';
import PauseIcon from 'vue-material-design-icons/Pause.vue';
import EmoticonExcitedOutlineIcon from 'vue-material-design-icons/EmoticonExcitedOutline.vue';
import LibraryOutlineIcon from 'vue-material-design-icons/LibraryOutline.vue';
import LightbulbVariantOutlineIcon from 'vue-material-design-icons/LightbulbVariantOutline.vue';
import { Line, Pie } from 'vue-chartjs';
import { number } from 'vue-number-animation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

import { db, speakerAudioStorage } from '@/firebase';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { formatTime } from '../utils';
import { getDownloadURL, ref, uploadBytesResumable, updateMetadata } from "firebase/storage";
import { WaveSurferPlayer } from '@meersagor/wavesurfer-vue';
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";

export default {
components: {
    EmoticonExcitedOutlineIcon,
    LibraryOutlineIcon,
    LightbulbVariantOutlineIcon,
    ForumIcon,
    PlayIcon,
    PauseIcon,
    Line,
    Pie,
    number,
    WaveSurferPlayer,
},
 data() { 
   return {
     classes: [],
     wavesurferCurrentTimes: [],
     maxConfidenceOf5And6: 0,
     groupedSessions: [],
     groupedSessionsByCourse: [],
     groupedSessionsStats: [],
     words: [],
     longestWords: [],
     sessionLimit: 3,
     userName: "",
     audioFile1: null,
     audioUrl: "",
     audioPlayer: null,
     audiosPlaying: {},
     companionAudioFilePath: null,
     lastPlayedAudioRef: null,
     numberFrom: 0,
     numberDuration: 5,
     nlpEntities: [],
     wavesurfers: {},
     wavesurfersPlaying: {},
     lineChartOptions: {
        responsive: true,
        scales: {
          y: {    
            suggestedMin: 0,
            suggestedMax: 5         
          }
        }
      },
      speakerAnalysisLineChartOptions: {
        responsive: true,
        scales: {
          y: {    
            suggestedMin: 0,
            suggestedMax: 100         
          }
        }
      },
      pieOptions: {
        plugins: {
          title: {
            display: true,
            text: "線上/實體比率",
            font: {
              size: 24,
              family: 'Helvetica Neue'
            }
          },
        },
        responsive: true
      },
      studentAveragePieOptions: {
        plugins: {
          title: {
            display: true,
            text: "學生積極參與比率",
            font: {
              size: 24,
              family: 'Helvetica Neue'
            }
          },
        },
        responsive: true
      }
   }
 },

 onSubmitProfileForm(data) {
 console.log(data)
      this.$vbsModal.close();
    },
beforeMount: async function() {
   /*     this.$vbsModal.open({
        content: EnterPasswordComponent,
        size: ModalSize.LARGE,
        staticBackdrop: true,
        contentProps: {
          emailAdress: this.email,
          privateAccount: this.private,
          userName: this.username,
          staticBackdrop: this.staticBackdrop,
          center: this.centered,
        },
        contentEmits: {
          onUpdate: this.onSubmitProfileForm,
        },
        center: true,
        backgroundScrolling: true,
      });
      */

},
methods: {
    readyWaveSurferHandler(ws, id, type, timeData) {
      console.log("ready wavesurfer:",id);
      // Give regions a random color when they are created
     // const random = (min, max) => Math.random() * (max - min) + min
      //const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`

      // Add regions plugin
      
      // Regions 
      if (timeData) {
        const wsRegions = ws.registerPlugin(RegionsPlugin.create());
        const regions = timeData.map(item=>({
          start: item.startTime,
          end: item.endTime,
          color: (type == 'teacher') ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)'
        }));
        console.log('found regions');
        ws.on('decode', () => {
          console.log('on decode add region');
          regions.forEach((region) => {
            wsRegions.addRegion(region);
          });
        });
      }


      // Create some regions at specific time ranges



      this.wavesurfers[id] = ws;
    },
    timeUpdateHandler(time, id) {
      this.wavesurferCurrentTimes[id] = formatTime(time);
    },
    scrollIntoView(event) {
        event.preventDefault()
        const href = event.target.getAttribute('href')
        const el = href ? document.querySelector(href) : null
        if (el) {
        el.scrollIntoView();

        }
     },
    playWavesurfer(id) {
      this.wavesurfers[id].playPause();
      if (!this.wavesurfersPlaying[id]) { 
        this.wavesurfersPlaying[id] = true;
      }
      else {
        this.wavesurfersPlaying[id] = false;
      }
      
    },
    playAudio(audioRef) {
     if(audioRef) {
        if(this.lastPlayedAudioRef != audioRef) {
            const url = 'https://firebasestorage.googleapis.com/v0/b/predictions-ui.appspot.com/o/' + audioRef + '?alt=media';
            this.audioPlayer = new Audio(url);
            this.audioPlayer.addEventListener("ended", function(){ this.audiosPlaying[audioRef] = false; }, false);
        }
        this.audioPlayer.play();
        this.lastPlayedAudioRef = audioRef;
        this.audiosPlaying[audioRef] = true;
     }
    },
    numberFormat(number) {
      return (number/60).toFixed(2);
    },
    playAnimation() {
        this.$refs.number2.play()
    },
    pauseAudio(audioRef) {
        this.audioPlayer.pause();
        this.audiosPlaying[audioRef] = false;
    },
    audioStoppedPlaying() {
        this.audioPlaying = false;
    },
    clickAudioUploadButtonStudent(ref) {
      this.$refs['student_file_' + ref][0].click();
    },
    clickAudioUploadButtonTeacher(ref) {
      this.$refs['teacher_file_' + ref][0].click();
    },
    async uploadAudioFile(session, prefix) {
      this.audioFile1 = event.target.files[0];
      const fileExtension = this.audioFile1.name.split(".").pop();


      const audioFileRef = ref(speakerAudioStorage, `${session.audioRef.split('.wav')[0]}/${prefix}.${fileExtension}`);
      // Upload the audio file
      const uploadTask = uploadBytesResumable(audioFileRef, this.audioFile1);
      
      session.audioUploadProgress = -1;
      uploadTask.on('state_changed',
        (snapshot)=>{
          session.audioUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('upload progress: ' + session.audioUploadProgress);
        },
        null,
        null
      );
      uploadTask.then(async (uploadedFile)=>{
        console.log('file upload complete')
        // Add the audio file metadata.
        const newMetadata = {
          cacheControl: 'public,max-age=2629800000', // 1 month
          contentType: uploadedFile.metadata.contentType
        };   
        if (this.companionAudioFilePath) {
          newMetadata.customMetadata = {  companionAudioFilePath: this.companionAudioFilePath  }
        }
        await updateMetadata(audioFileRef, newMetadata);     
        this.companionAudioFilePath = await getDownloadURL(audioFileRef);
        session.audioUploadProgress = null;
        session[prefix + 'AudioUploaded'] = true;
        if (session.studentAudioUploaded && session.techerAudioUploaded) {
          session.speakerAnalysis = {
            completed: false
          }
        }
      });
    },
    analyzeText(words) {
        const request = {
          method: "POST",
          mode: "cors",
          cache: "default",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ inputText: words.toString() })
        };
        return fetch('http://localhost:3000/NLP/',request).then(response => response.json())
            .then(data => {
                console.log('finished NLP');
                console.log(data.entities.entities);
                this.nlpEntities = data.entities.entities;
            })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

},
 mounted: async function() {    
      console.log(`lifecycle method to get results for: ${this.emailAddress}`)
    
      const emailQuery = query(collection(db, "class_records"), where("studentEmail", "==", this.emailAddress));
      
      const sessionsSnap = await getDocs(emailQuery);
      const groupedSessions = [];
      const groupedSessionsByCourse = [];
      const sessions = [];

      sessionsSnap.forEach((doc) => {
        const session = doc.data();
        session.studentId = session.studentId.replaceAll(/\s/g, '');  
        sessions.push(session);
      });

      console.log('got sessions:');
      console.log(sessions);

      const sessionsGroupedByStudentId = sessions.reduce((group, arr) => {   
        const { studentId } = arr;
        group[studentId] = group[studentId] ?? [];
        group[studentId].push(arr);
        return group;
      },{});

      console.log('sessions grouped by studentId:');
      console.log(sessionsGroupedByStudentId);

      Object.entries(sessionsGroupedByStudentId).forEach(async (sessions, index) => {
        const deduplicatedSessions = sessions[1].filter((value, index, self) =>
          index === self.findIndex((t) => (
            (t.date === value.date) && (t.teacherName === value.teacherName) && (t.teacherComments === value.teacherComments)
          ))
        );
        const sortedSessions = deduplicatedSessions.sort((a,b)=>{
          return new Date(a.date) - new Date(b.date);
        }).reverse();

        // Build line chart data
        const sessionDates = sortedSessions.map(sess=>{ return sess.date }).reverse();
        const sessionDatesForSpeakerAnalysis = sortedSessions.filter(sess=>{ return sess.speakerAnalysis && sess.speakerAnalysis.completed; }).map(sess=>{ return sess.date }).reverse();
        const participationSessionData = [];
        const happySessionData = [];
        const sittingSessionData = [];
        const attentionSessionData = [];
        const speakerAnalysisData = [];
        let studentTotal = 0;
        let teacherTotal = 0;

        sessionDates.forEach(date=>{
          const ratings = sortedSessions.filter(sess=>{ return sess.date == date;})[0].ratings;
          if (ratings) {
            participationSessionData.push(ratings.participation);
            happySessionData.push(ratings.happy);
            sittingSessionData.push(ratings.sitting);
            attentionSessionData.push(ratings.attention);
          }
        });
        
        sessionDatesForSpeakerAnalysis.forEach(date=>{
          const speakerAnalysis = sortedSessions.filter(sess=>{ return sess.date == date;})[0].speakerAnalysis;
          studentTotal += Number(speakerAnalysis.studentPercent)
          teacherTotal += Number(speakerAnalysis.teacherPercent)
          speakerAnalysisData.push(speakerAnalysis.studentPercent);
        });
        
        // Build language statistics
        const onlineSessionsPercent = Math.round((sortedSessions.filter(sess=>{ return sess.sessionType == '線上' }).length / sortedSessions.length) * 100);
        let cantoneseMinutes = 0;
        let englishMinutes = 0;
        let putonghuaMinutes = 0;
        let japaneseMinutes = 0;
        let koreanMinutes = 0;

        sortedSessions.forEach(async (sess) => {
          const sessionMins = (sess.sessionType == '線上') ? 30 : 60;
          if (sess.sessionLanguage) {
            if (sess.sessionLanguage == '廣東話') cantoneseMinutes += sessionMins;
            if (sess.sessionLanguage == '英語') englishMinutes += sessionMins;
            if (sess.sessionLanguage == '普通話') putonghuaMinutes += sessionMins;
            if (sess.sessionLanguage == '日文') japaneseMinutes += sessionMins;
            if (sess.sessionLanguage == '韓語') koreanMinutes += sessionMins;
          }
          else cantoneseMinutes += sessionMins;

          const studentAudioFileRef = ref(speakerAudioStorage, `${sess.audioRef.split('.wav')[0]}/student.m4a`);
          const teacherAudioFileRef = ref(speakerAudioStorage, `${sess.audioRef.split('.wav')[0]}/teacher.m4a`);



          if (sess.speakerAnalysis && sess.speakerAnalysis.completed) {

            sess.studentAudioFileLink = await getDownloadURL(studentAudioFileRef);
            sess.teacherAudioFileLink = await getDownloadURL(teacherAudioFileRef);

            if ('bcf29106-ee2b-4e22-a0bd-c27b3831423f' == sess.audioRef.split('.wav')[0]) {
              console.log('finding link')
              console.log(sess.studentAudioFileLink);

            } 

            
            if (sess.speakerAnalysis.studentSilenceInfo) {

              const talkingLengths = [];

              sess.speakerAnalysis.studentSilenceInfo.forEach((item, index, arr)=>{
                const nextItem = index+1 < arr.length ? arr[index+1] : null;
                if(nextItem) {
                  talkingLengths.push({ talkingSecs: nextItem.stSec - item.endSec, index: index });
                }
              });

              

              const topTalkingLengths = talkingLengths.toSorted((a, b) => {
                                                        return (Number(a.talkingSecs) > Number(b.talkingSecs)) ? 1 : -1;
                                                      })
                                                      .slice(-5);

              if (topTalkingLengths.length) {
                sess.speakerAnalysis.studentTalkingLengths = topTalkingLengths.map(item=>{
                                                            return {
                                                              startTime: sess.speakerAnalysis.studentSilenceInfo[item.index].endSec,
                                                              endTime: sess.speakerAnalysis.studentSilenceInfo[item.index + 1].stSec
                                                            }});
                console.log(sess.speakerAnalysis.studentTalkingLengths);

              }


 
             
              




            }
          }
        });

        this.groupedSessionsStats[index] = {
          languageMinutes: {
            cantonese: cantoneseMinutes,
            english: englishMinutes,
            japanese: japaneseMinutes,
            korean: koreanMinutes,
            putonghua: putonghuaMinutes
          },
          onlineOfflineSessionData: {
            labels: ['線上', '實體'],
            datasets: [
              {
                  data: [onlineSessionsPercent, 100 - onlineSessionsPercent],
                  backgroundColor: ['#41B883', '#E46651']
              }
            ]
          },
          studentTeacherAverage: {
            labels: ['學生', '導師'],
            datasets: [{
              data: [studentTotal / sessionDatesForSpeakerAnalysis.length, teacherTotal / sessionDatesForSpeakerAnalysis.length],
              backgroundColor: ['#41B883', '#E46651']
            }]
          },
          averageStudentParticipationPercent: studentTotal / sessionDatesForSpeakerAnalysis.length,
          averageTeacherParticipationPercent: teacherTotal / sessionDatesForSpeakerAnalysis.length,
          participationData: {
            labels: sessionDates,
            datasets: [{

              label: '互動溝通',
              backgroundColor: '#499de0',
              borderColor: '#499de0',
              data: participationSessionData
            },
            {
              label: '快樂指數',
              backgroundColor: '#32c2d6',
              borderColor: '#32c2d6',
              data: happySessionData
            },
            {
              label: '安坐',
              backgroundColor: '#3bbb24',
              borderColor: '#3bbb24',
              data: sittingSessionData
            },
            {
              label: '專注投入',
              backgroundColor: '#f8ee6e',
              borderColor: '#f8ee6e',
              data: attentionSessionData
            }]
          },
          speakerAnalysisData: {
            labels: sessionDatesForSpeakerAnalysis,
            datasets: [{
              label: '學生積極參與',
              backgroundColor: '#499de0',
              borderColor: '#499de0',
              data: speakerAnalysisData
            }]

          }

        };

        groupedSessions.push(sortedSessions);
        groupedSessionsByCourse.push(Object.groupBy(sortedSessions, ({ sessionCourseName  }) => sessionCourseName ));
      })
      console.log('GROUPED SESSIONS:');
      console.log(groupedSessions);
      this.groupedSessions = groupedSessions;
      this.groupedSessionsByCourse = groupedSessionsByCourse;

 },
  props: {
    plugins: {
      type: Array,
      default: () => [Title],
    },
    teacherViewing: {
      required: false,
      type: String,
    },
    emailAddress: {
      required: true,
      type: String,
    }
  }

}
</script>
