<template>
  <nav>
      <ul class="nav navbar-nav align-items-start">
        <li class="nav-item">
            <a class="nav-link" href="#"></a>
        </li>
        <li class="text-light nav-item">
            <img src="~@/assets/rec_flag.png"/>
            <h3 class="navbar-text">為孩子帶來不一樣的學習體驗</h3>
        </li>  

    </ul>
    
  </nav>
<div class="container">
  <div class="container text-left">
   <h2>密碼：</h2>
    <div class="form-group">
     <input v-model="password" class="form-control" type="password">
     <button @click="go" type="button" class="btn btn-lg btn-primary">繼續</button>
    </div>
  </div>
</div>
</template>

<script>
import storageHelper from 'storage-helper';

export default {
 props: ['toPath', 'adminPassword'],
 data() { 
   return {
     password: ""
   }
 },
 methods: {

  go() {
   console.log('going back: ' + this.toPath);
   console.log('admin password: ' + this.adminPassword)
   storageHelper.setItem((this.adminPassword ? 'admin-password' : 'user-password'), this.password)
   this.$router.push(this.toPath);
 }

 }


}
</script>
