<template>
  <nav>
      <ul class="nav navbar-nav align-items-start">
        <li class="nav-item">
            <a class="nav-link" href="#"></a>
        </li>
        <li class="text-light nav-item">
            <img src="~@/assets/rec_flag.png"/>
            <h3 class="navbar-text">為孩子帶來不一樣的學習體驗</h3>
        </li>  

    </ul>
    
  </nav>
<div class="container mt-4">
  <div class="container text-left">
    <h2>Chat Log</h2>
    <div  v-for="message in messages" v-bind:key="message" class="mb-4 card card-body card-pill">
      <h4 class="card-title">{{message.role}}</h4>
      <p class="card-text">{{message.content}}</p>
    
    </div>
     <form  @submit.prevent="addUserMessage">
      <input ref="input" class="terminal-input form-control " placeholder="Enter question" autofocus v-model="question"/> 
    </form>
  </div>
</div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { db } from '@/firebase'
import { getDoc, setDoc, doc } from 'firebase/firestore'

export default {
 data() { 
   return {
     classes: [],
     messages: [],
     maxConfidenceOf5And6: 0,
     userName: "",
     emailAdress: "",
     question: "",
     chatId: ""
   }
 },
 methods: {
    async addUserMessage() {
      console.log('adding message')
      const messages = [...this.messages, ...[{
        content: this.question,
        role: "user"
      }]];

      await setDoc(doc(db, "chats", this.chatId), {
        messages: messages
      });

      console.log('updated')

   },
 },

 onSubmitProfileForm(data) {
    console.log('form data')
    console.log(data)
      this.$vbsModal.close();
    },
 mounted: async function() { 
      const results = reactive({ data: {}, classData: [] });
      const route = useRoute();
      const resultId = computed(()=> route.params.id) 
  
      console.log(`lifecycle method to get results for: ${resultId.value}`)
      const docRef = doc(db, "chats", route.params.id);
      
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        console.log("Document data:", docSnap.data());
        results.data = docSnap.data()
        this.chatId = docSnap.id;
        this.messages = results.data.messages;

        

       
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }


 },

}
</script>
