
import { defineComponent, PropType } from "vue";
export interface UserProfile {
  username: string;
  password: string;
  email: string;
  private: boolean;
}
export default defineComponent({
  name: "EditProfileComponent",
  emits: ["onSubmit"],
  data() {
    return {
      username: "",
      password: "",
      email: "",
      private: false,
    };
  },
  created() {
    this.email = this.emailAdress;
    this.username = this.userName;
    this.password = "";
    this.private = this.privateAccount;
  },
  methods: {
    close() {
      this.$vbsModal.close();
    },
    updateProfile() {
    console.log('checking password')
    //this.$vbsModal.close();
      this.$emit("onSubmit", {
        username: this.username,
        email: this.email,
        private: this.private,
      });
    },
    deleteProfile() {
      this.$vbsModal
        .confirm({
          message: "Are you sure you want to delete your profile?",
          title: "Delete Profile",
          staticBackdrop: this.staticBackdrop,
          center: this.center,
        })
        .then((confirmed) => {
          if (confirmed) {
            this.$vbsModal.confirm({
              icon: "bi bi-x-octagon-fill",
              message: "Sorry you can't do this!",
              title: "Deletion Failed",
              leftBtnText: "Close",
              rightBtnText: "Ok",
              staticBackdrop: this.staticBackdrop,
              center: this.center,
            });
          }
        });
    },
  },
  props: {
    emailAdress: {
      type: String as PropType<string>,
      default: "",
    },
    privateAccount: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    userName: {
      type: String as PropType<string>,
      default: "",
    },
    staticBackdrop: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    center: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
