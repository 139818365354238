<template>
  <nav>
      <ul class="nav navbar-nav align-items-start">
        <li class="nav-item">
            <a class="nav-link" href="#"></a>
        </li>
        <li class="text-light nav-item">
            <img src="~@/assets/rec_flag.png"/>
            <h3 class="navbar-text">為孩子帶來不一樣的學習體驗</h3>
        </li>  

    </ul>
    
  </nav>
  <div class="container-fluid classes">
    <div class="card main-card">
      <img src="/img/Course_5.jpg" class="card-img-top" alt="...">

      <div class="card-body main-card-body">

      <div class="flex-sm-right">
        <div class="progress" v-bind:data-percentage="maxConfidenceOf5And6">
          <span class="progress-left">
            <span class="progress-bar"></span>
          </span>
          <span class="progress-right">
            <span class="progress-bar"></span>
          </span>
          <div class="progress-value">
            <div>
              {{maxConfidenceOf5And6}}%<br/>
              <span>Suitable</span>
            </div>
          </div>
          </div>
      </div>
      </div>
    </div>
    <div v-for="{ name, courseId } in classes" v-bind:key="courseId" class="card flex-row"><img class="card-img-sm-left" v-bind:src="'/img/Course_' + courseId + '.jpg'"/>
      <div class="card-body">
        <div class="row">
          <h4 class="card-title h5 h4-sm">
            {{name}}
          </h4>
          <p class="float-left">課程</p>
        </div>
        
       
        <div class="row">
          <p class="card-text float-left">學習焦點：</p>
        </div>    
      </div>
    </div>
</div>

</template>

<script>
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { db } from '@/firebase'
import { getDoc, getDocs, collection, query, doc, where } from 'firebase/firestore'
import EnterPasswordComponent from "../components/EnterPasswordComponent.vue";
import { ModalSize } from "vue-bs-modal";
import storageHelper from 'storage-helper';

export default {
 data() { 
   return {
     classes: [],
     maxConfidenceOf5And6: 0,
     userName: "",
     emailAdress: ""
   }
 },
 onSubmitProfileForm(data) {
    console.log('form data')
    console.log(data)
      this.$vbsModal.close();
    },
beforeMount: async function() {
        this.$vbsModal.open({
        content: EnterPasswordComponent,
        size: ModalSize.LARGE,
        staticBackdrop: true,
        contentProps: {
          emailAdress: this.email,
          privateAccount: this.private,
          userName: this.username,
          staticBackdrop: this.staticBackdrop,
          center: this.centered,
        },
        contentEmits: {
          onSubmit: this.onSubmitProfileForm
        },
        center: true,
        backgroundScrolling: true,
      });
},
 mounted: async function() {



    
      const results = reactive({ data: {}, classData: [] });
      const route = useRoute();
      const resultId = computed(()=> route.params.id) 
  
      console.log(`lifecycle method to get results for: ${resultId.value}`)
      const docRef = doc(db, "results", route.params.id);
      
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        console.log("Document data:", docSnap.data());
        results.data = docSnap.data()
        // Check password
        if (storageHelper.getItem('user-password') !== results.data.password) {
          this.$router.push('/login?q=' + route.path);
        }

        const confidences = results.data.result.data?.[0].confidences;

        const class5confidence = confidences.filter((x)=>{ return x.label == "5"})?.[0].confidence;
        const class6confidence = confidences.filter((x)=>{ return x.label == "6"})?.[0].confidence;

        const sortedConfidences = confidences.filter((x)=>{ return (Number(x.label) > 0) && (Number(x.label) < 5); }).sort((x)=>{ return x.confidence});
        this.maxConfidenceOf5And6 = Math.round(Math.max(class5confidence, class6confidence) * 100);

        if (class5confidence > 0.7 || class6confidence > 0.7) {
          console.log('confidence high')

          
        }
        else {
          // Only display the top 2
          const courseIds = [sortedConfidences[0].label, sortedConfidences[1].label]

          const classQuery = query(collection(db, "classes"), where("courseId", "in", courseIds));
          const classSnap = await getDocs(classQuery);
          const classes = [];
          classSnap.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
           
            const classData = doc.data();
            classes.push({ ...sortedConfidences.filter((x)=>{ return x.label == classData.courseId})?.[0],
            ...classData });

          });
          this.classes = classes;

        }
       
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }


 },

}
</script>
