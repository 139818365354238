import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Modal from "vue-bs-modal";
import VueKonva from 'vue-konva';
import VueNumber from 'vue-number-animation';
import { AVPlugin } from "vue-audio-visual";
import { BootstrapVueNext } from 'bootstrap-vue-next';

const app = createApp(App);
app.use(router).use(BootstrapVueNext).use(Modal).use(VueKonva).use(VueNumber).use(AVPlugin).mount("#app");