import { initializeApp } from "firebase/app";
import {  getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvC4rxDJdjk9kox5_Gltn2o6YtWVWw50Y",
  authDomain: "predictions-ui.firebaseapp.com",
  projectId: "predictions-ui",
  storageBucket: "predictions-ui.appspot.com",
  messagingSenderId: "484142946950",
  appId: "1:484142946950:web:2f878c03df3f9488a48f07"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const speakerAudioStorage = getStorage(app, "gs://speaker-analysis-audio");